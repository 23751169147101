const recentlyViewedProductsFromStorage = JSON.parse((typeof (Storage) !== "undefined" && localStorage.recentlyViewedProducts) || "[]");
const wishlistProductIdsFromStorage = JSON.parse((typeof (Storage) !== "undefined" && localStorage.wpids) || "[]");

const productState = {
    productBackUrl: null,
    selectedProduct: null,
    recentlyViewedProducts: recentlyViewedProductsFromStorage,
    wishlistProductIds: wishlistProductIdsFromStorage,
};

const getters = {
    productBackUrl: (state) => state.productBackUrl,
    selectedProduct: (state) => state.selectedProduct,
    recentlyViewedProducts: (state) => state.recentlyViewedProducts,
    wishlistProductIds: (state) => state.wishlistProductIds,
};

const actions = {

    productBackUrl({ commit }, url) {
        setTimeout(() => {
            commit("productBackUrl", url);
        }, 10);
    },

    selectProduct({ commit }, product) {
        commit("selectProduct", product);
    },

    recentlyViewed({ commit }, product) {
        setTimeout(() => {
            commit("recentlyViewed", product);
        }, 10);
    },

    removeAllWishlistProductsFromLS({ commit }) {
        setTimeout(() => {
            commit("removeAllWishlistProductsFromLS");
            if (typeof (Storage) !== "undefined") {
                try {
                    localStorage.setItem("wpids", JSON.stringify([]));
                } catch (e) {
                    //
                }
            }
        }, 10);
    },

    addAllWishlistProductsToLS({ commit }, productsIds) {
        setTimeout(() => {
            commit("addAllWishlistProductsToLS", productsIds);
            if (typeof (Storage) !== "undefined") {
                try {
                    localStorage.setItem("wpids", JSON.stringify(productsIds));
                } catch (e) {
                    //
                }
            }
        }, 10);
    },

    addWishlistProductToLS({ commit }, id_product) {
        setTimeout(() => {
            commit("addWishlistProductToLS", id_product);
        }, 10);
    },

    removeWishlistProductFromLS({ commit }, id_product) {
        setTimeout(() => {
            commit("removeWishlistProductFromLS", id_product);
        }, 10);
    },
};

const mutations = {
    productBackUrl(state, url) {
        state.productBackUrl = url;
    },

    selectProduct(state, product) {
        state.selectedProduct = product;
    },

    removeAllWishlistProductsFromLS(state) {
        state.wishlistProductIds = [];
    },

    addAllWishlistProductsToLS(state, productsIds) {
        state.wishlistProductIds = productsIds;
    },

    addWishlistProductToLS(state, id_product) {
        if (!state.wishlistProductIds.includes(id_product)) {
            state.wishlistProductIds.push(id_product);

            if (typeof (Storage) !== "undefined") {
                try {
                    localStorage.setItem("wpids", JSON.stringify(state.wishlistProductIds));
                } catch (e) {
                    //
                }
            }
        }
    },

    removeWishlistProductFromLS(state, id_product) {
        state.wishlistProductIds = state.wishlistProductIds.filter((id) => id !== id_product);
        if (typeof (Storage) !== "undefined") {
            try {
                localStorage.setItem("wpids", JSON.stringify(state.wishlistProductIds));
            } catch (e) {
                //
            }
        }
    },

    recentlyViewed(state, product) {
        if (state.recentlyViewedProducts.length > 12) {
            state.recentlyViewedProducts.splice(12, 10);
        }

        let exists = false;

        state.recentlyViewedProducts.forEach((rvp) => {
            if (rvp.id_product === product.id_product) {
                exists = true;
            }
        });

        if (!exists) {
            if (state.recentlyViewedProducts.length >= 12) {
                state.recentlyViewedProducts.splice(state.recentlyViewedProducts.length - 1, 1);
            }
            state.recentlyViewedProducts.splice(0, 0, product);

            if (typeof (Storage) !== "undefined") {
                try {
                    localStorage.setItem("recentlyViewedProducts", JSON.stringify(state.recentlyViewedProducts));
                } catch (e) {
                    //
                }
            }
        }
    },
};

export default {
    state: productState,
    getters,
    actions,
    mutations,
};
