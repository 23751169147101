import config from '../config'
import {warn, exists} from '../utils'
import track from './track'

/**
 * Enable route autoTracking page
 * @param  {VueRouter} router
 */
export default function (router) {
    if (config.manual && !router && config.autoTracking) {
        warn('auto-tracking doesn\'t work without a router instance.');
        return
    }

    if (!config.autoTracking || !router) {
        return
    }

    // Track the first page when the user lands on it
    const route = router.currentRoute;

    if (!exists(route.name)) {
        track("PageView");
    }

    // Track all other pages
    router.afterEach((to, from) => {
        if (exists(to.name)) {
            return
        }

        track("PageView");
    });
}