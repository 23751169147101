const MaterialTextAreaDirective = {
    beforeMount(el) {
        el.textareaAutoResize = function textareaAutoResize() {
            const ta = this;
            const computedStyleTextarea = getComputedStyle(ta);
            const fontFamily = computedStyleTextarea.getPropertyValue("font-family");
            const fontSize = computedStyleTextarea.getPropertyValue("font-size");
            const lineHeight = computedStyleTextarea.getPropertyValue("line-height");

            const hiddenDiv2 = document.querySelector(".hiddendiv");
            if (hiddenDiv2 && fontFamily) {
                hiddenDiv2.style.fontFamily = fontFamily;
            }
            if (hiddenDiv2 && fontSize) {
                hiddenDiv2.style.fontSize = fontSize;
            }
            if (hiddenDiv2 && lineHeight) {
                hiddenDiv2.style.lineHeight = lineHeight;
            }

            if (ta.getAttribute("wrap") === "off") {
                hiddenDiv2.style.overflowWrap = "normal";
                hiddenDiv2.style.whiteSpace = "pre";
            }

            hiddenDiv2.textContent = `${ta.value}`;
            const content = hiddenDiv2.innerHTML.replace(/\n/g, "<br>");
            hiddenDiv2.innerHTML = content;

            // When textarea is hidden, width goes crazy.
            // Approximate with half of window size
            if (ta.offsetWidth > 0 || ta.offsetHeight > 0) {
                hiddenDiv2.style.width = `${ta.offsetWidth}px`;
            } else {
                hiddenDiv2.style.width = `${document.documentElement.clientWidth / 2}px`;
            }

            /**
             * Resize if the new height is greater than the
             * original height of the textarea
             */
            if (parseInt(ta.getAttribute("data-original-height")) <= hiddenDiv2.offsetHeight) {
                ta.style.height = `${hiddenDiv2.offsetHeight}px`;
            } else if (ta.value.length < parseInt(ta.getAttribute("data-previous-length"))) {
                /**
                 * In case the new height is less than original height, it
                 * means the textarea has less text than before
                 * So we set the height to the original one
                 */
                ta.style.height = parseInt(ta.getAttribute("data-original-height"));
            }
            ta.setAttribute("data-previous-length", ta.value.length);
        };

        el.addEventListener("keyup", el.textareaAutoResize);
        el.addEventListener("keydown", el.textareaAutoResize);
        el.addEventListener("blur", () => {
            const hiddenDiv = document.querySelector(".hiddendiv");
            hiddenDiv.innerHTML = "";
        });
    },

    mounted(el) {
        let hiddenDiv = document.querySelector(".hiddendiv");
        if (!hiddenDiv) {
            hiddenDiv = document.createElement("div");
            hiddenDiv.classList.add("hiddendiv");
            hiddenDiv.classList.add("common");
            document.getElementsByTagName("body")[0].appendChild(hiddenDiv);
        }

        const allTextareas = document.querySelectorAll(".materialize-textarea");
        allTextareas.forEach(($textarea) => {
            /**
             * Instead of resizing textarea on document load,
             * store the original height and the original length
             */
            $textarea.setAttribute("data-original-height", $textarea.offsetHeight);
            $textarea.setAttribute("data-previous-length", $textarea.value.length);
        });

        el.textareaAutoResize();
    },

    updated(el) {
        if (!el.value) {
            el.style.height = "";
        }
        el.textareaAutoResize();
    },
};

export default MaterialTextAreaDirective;
