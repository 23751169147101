import { createRouter, createWebHistory } from "vue-router";
import { captureException } from "@sentry/vue";

import shop from "./shop";
import store from "./store";

// console.log("user already logged in: " + localStorage.loggedIn);
if (typeof (Storage) !== "undefined") {
    if (localStorage.loggedIn && localStorage.loggedIn === "true") {
        store.dispatch("loggedIn");
    }
    if (localStorage.loggedIn && localStorage.loggedIn === "false") {
        store.dispatch("loggedOut");
    }
}

const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        return savedPosition;
    }
    const position = {};
    // new navigation.
    // scroll to anchor by returning the selector
    if (to.hash) {
        // position.selector = to.hash
    }
    // check if any matched route config has meta that requires scrolling to top
    if (to.matched.some((m) => m.meta.scrollToTop)) {
        // cords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.left = 0;
        position.top = 0;
    }
    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    return position;
};

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior,
    routes: [
        {
            path: "",
            component: () => import("./views/Home.vue"),
            name: "Home",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/men",
            component: () => import("./views/Home.vue"),
            name: "Men",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/women",
            component: () => import("./views/Home.vue"),
            name: "Women",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/kids",
            redirect: "/kids-store",
        },
        {
            path: "/kids-store",
            component: () => import("./views/Home.vue"),
            name: "Kids",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/:category_link_rewrite?/:id_product(\\d+)-:product_link_rewrite.html",
            component: () => import("./views/ProductDetails.vue"),
            name: "ProductDetails",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/:category_link_rewrite?/:id_product(\\d+)-:product_link_rewrite/write-review",
            component: () => import("./views/WriteReview.vue"),
            name: "WriteReview",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/:id_category(\\d+)-:category_link_rewrite/:filterNameValue?",
            component: () => import("./views/ProductList.vue"),
            name: "ProductList",
            meta: {
                requiresAuth: false,
                scrollToTop: false,
            },
        },
        {
            path: "/search",
            component: () => import("./views/SearchProductList.vue"),
            name: "Search",
            meta: {
                requiresAuth: false,
                scrollToTop: false,
            },
        },
        {
            path: "/:id_manufacturer(\\d+)_:brand_link_rewrite",
            component: () => import("./views/BrandProductList.vue"),
            name: "BrandProductList",
            meta: {
                requiresAuth: false,
                scrollToTop: false,
            },
        },
        {
            path: "/quick-order",
            component: () => import("./views/MyBag.vue"),
            name: "MyBag",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/quick-order/:_id_cart",
            redirect: "/quick-order",
        },
        {
            path: "/content/16-men",
            redirect: "/men",
        },
        {
            path: "/content/15-women",
            redirect: "/women",
        },
        {
            path: "/content/:id_cms(\\d+)-:cms_link_rewrite",
            component: () => import("./views/Cms.vue"),
            name: "Cms",
            meta: {
                requiresAuth: false,
                scrollToTop: true,
            },
        },
        {
            path: "/contact-us",
            component: () => import("./views/ContactUs.vue"),
            name: "ContactUs",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/authentication",
            component: () => import("./views/Login.vue"),
            name: "Login",
            meta: {
                scrollToTop: true,
            },
            beforeEnter: (to, from, next) => {
                if (store.getters.loggedIn) {
                    if (to.query.back) {
                        next({ path: to.query.back });
                    } else {
                        next({ path: "/" });
                    }
                } else {
                    next();
                }
            },
        },
        {
            path: "/authentication/signup/otp",
            component: () => import("./views/SignupOTP.vue"),
            name: "SignupOTP",
            meta: {
                scrollToTop: true,
            },
            beforeEnter: (to, from, next) => {
                if (store.getters.loggedIn) {
                    if (to.query.back) {
                        next({ path: to.query.back });
                    } else {
                        next({ path: "/" });
                    }
                } else {
                    next();
                }
            },
        },
        {
            path: "/authentication/signup/details",
            component: () => import("./views/SignupDetails.vue"),
            name: "SignupDetails",
            meta: {
                scrollToTop: true,
            },
            beforeEnter: (to, from, next) => {
                if (store.getters.loggedIn) {
                    if (to.query.back) {
                        next({ path: to.query.back });
                    } else {
                        next({ path: "/" });
                    }
                } else {
                    next();
                }
            },
        },
        {
            path: "/forgot-otp-verify",
            component: () => import("./views/ForgotOTP.vue"),
            name: "ForgotOTP",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/reset-password",
            component: () => import("./views/ResetPasswordOTP.vue"),
            name: "ResetPasswordOTP",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/select-delivery-address",
            component: () => import("./views/SelectDeliveryAddress.vue"),
            name: "SelectDeliveryAddress",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/select-payment",
            component: () => import("./views/SelectPayment.vue"),
            name: "SelectPayment",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/thank-you",
            component: () => import("./views/ThankYou.vue"),
            name: "ThankYou",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/payment-failed",
            component: () => import("./views/PaymentFailed.vue"),
            name: "PaymentFailed",
            meta: {
                scrollToTop: true,
            },
        },
        {
            path: "/wishlist",
            component: () => import("./views/Wishlist.vue"),
            name: "Wishlist",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/my-account",
            component: () => import("./views/MyAccount.vue"),
            name: "MyAccount",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/identity",
            component: () => import("./views/Identity.vue"),
            name: "Identity",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/addresses",
            component: () => import("./views/Addresses.vue"),
            name: "Addresses",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/giftcard",
            redirect: "/wallet",
        },
        {
            path: "/wallet",
            component: () => import("./views/Wallet.vue"),
            name: "Wallet",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/order-history",
            component: () => import("./views/OrderHistory.vue"),
            name: "OrderHistory",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/order-history/:id_order",
            component: () => import("./views/OrderDetails.vue"),
            name: "OrderDetails",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/order-reviews",
            component: () => import("./views/OrderReviews.vue"),
            name: "OrderReviews",
            meta: {
                requiresAuth: true,
                scrollToTop: true,
            },
        },
        {
            path: "/:catchAll(.*)",
            component: () => import("./views/PageNotFound.vue"),
            name: "PageNotFound",
            meta: {
                scrollToTop: true,
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // get user state from backend
        shop.getUserState((response) => {
            if (response.user) {
                store.dispatch("loggedIn");
            } else {
                store.dispatch("loggedOut");
            }

            if (!store.getters.loggedIn) {
                next({
                    path: "/authentication",
                    query: { back: to.fullPath },
                });
            } else {
                next();
            }
        });
        /*
        if (typeof (Storage) !== "undefined" && typeof localStorage.loggedIn === "undefined") {
            // get user state from backend
            shop.getUserState((response) => {
                if (response.user) {
                    store.dispatch("loggedIn");
                } else {
                    store.dispatch("loggedOut");
                }

                if (!store.getters.loggedIn) {
                    next({
                        path: "/authentication",
                        query: { back: to.fullPath },
                    });
                } else {
                    next();
                }
            });
        } else if (!store.getters.loggedIn) {
            next({
                path: "/authentication",
                query: { back: to.fullPath },
            });
        } else {
            next();
        }
        */
    } else {
        if (to.name === "PageNotFound") {
            captureException(new Error("PageNotFound"), {
                extra: {
                    params: JSON.stringify(from.params),
                    path: from.path,
                    query: from.query,
                    hash: from.hash,
                },
            });
        }
        next();
    }
});

router.isReady().then(() => {
    store.dispatch("routerReady");
});

export default router;
