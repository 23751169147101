const productListState = {
    showFilterBar: false,
    resetFilters: false,
};

const getters = {
    showFilterBar: (state) => state.showFilterBar,
    resetFilters: (state) => state.resetFilters,
};

const actions = {
    resetFilters({ commit }, value) {
        setTimeout(() => {
            commit("resetFilters", value);
        }, 10);
    },
    showFilterBar({ commit }) {
        setTimeout(() => {
            commit("showFilterBar", true);
        }, 10);
    },
    hideFilterBar({ commit }) {
        setTimeout(() => {
            commit("showFilterBar", false);
        }, 10);
    },
};

const mutations = {
    resetFilters(state, resetFilters) {
        state.resetFilters = resetFilters;
    },
    showFilterBar(state, showFilterBar) {
        state.showFilterBar = showFilterBar;
    },
};

export default {
    state: productListState,
    getters,
    actions,
    mutations,
};
