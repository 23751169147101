const $materializeFields = function $materializeFields() {
    const inputSelector = "input[type=text], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=number], input[type=search], textarea";
    const matches = document.querySelectorAll(inputSelector);

    Array.prototype.forEach.call(matches, (object) => {
        const element = object.nextSibling;
        const placeholder = object.getAttribute("placeholder");
        if (object.value.length > 0 || object.autofocus || placeholder !== null) {
            element.classList.add("active");
        } else if (object.validity) {
            if (object.validity.badInput === true) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        } else {
            element.classList.remove("active");
        }

        object.classList.remove("valid");
        object.classList.remove("invalid");
    });
};

const MaterialFieldDirective = {
    beforeMount(el) {
        function vf() {
            const len = el.value.length;
            if (len === 0 && el.validity.badInput === false) {
                if (el.classList.contains("validate")) {
                    el.classList.remove("valid");
                    el.classList.remove("invalid");
                }
            } else if (el.classList.contains("validate")) {
                if (el.validity.valid) {
                    el.classList.remove("invalid");
                    el.classList.add("valid");
                } else {
                    el.classList.remove("valid");
                    el.classList.add("invalid");
                }
            }
        }

        function focusHandler() {
            const element = el.nextSibling;
            if (element.tagName === "LABEL") {
                element.classList.add("active");
            }
        }

        function blurHandler() {
            const element = el.nextSibling;

            if (element.tagName === "LABEL") {
                const placeholder = this.getAttribute("placeholder");
                if (el.value.length === 0 && el.validity.badInput !== true && placeholder === null) {
                    element.classList.remove("active");
                }

                // check validity
                vf();
            }
        }

        el.addEventListener("focus", focusHandler);
        el.addEventListener("blur", blurHandler);
    },
    unmounted: function unmounted(el) {
        el.removeEventListener("focus", el.focusHandler);
        el.removeEventListener("blur", el.blurHandler);
    },
};

export { MaterialFieldDirective, $materializeFields };
