const LazyLoadWebP = {};

LazyLoadWebP.install = (Vue) => {
    let isSupportWebp = false;
    try {
        isSupportWebp = (document.createElement("canvas").toDataURL("image/webp").indexOf("data:image/webp") === 0);
    } catch (err) {
        console.info("This browser not support Webp");
    }
    let passiveSupported = false;
    try {
        const options = Object.defineProperty({}, "passive", {
            get: () => {
                passiveSupported = true;
                return false;
            },
        });
        window.addEventListener("test", options, options);
        window.removeEventListener("test", options, options);
    } catch (err) {
        passiveSupported = false;
    }

    Vue.config.globalProperties.$isSupportWebp = isSupportWebp;
    Vue.config.globalProperties.$passiveSupported = passiveSupported;

    const lazyMethod = function lazyMethod(el, binding) {
        const { lazy, placeholder } = { lazy: true, ...binding.value };
        // console.log(`lazy: ${lazy}, placeholder: ${placeholder}`);

        function loadImage() {
            if (placeholder) {
                if (isSupportWebp) {
                    el.setAttribute("src", placeholder.replace(/(\.jpg|\.png)/g, ".jpg.webp"));
                } else {
                    el.setAttribute("src", placeholder);
                }
            }

            let dataSrc = el.getAttribute("data-src");
            if (isSupportWebp) {
                dataSrc = dataSrc.replace(/(\.jpg|\.png)/g, ".jpg.webp");
            }
            if (lazy) {
                el.classList.add("lazy");
            }
            if (placeholder) {
                setTimeout(() => {
                    el.setAttribute("src", dataSrc);
                }, 100);
            } else {
                el.setAttribute("src", dataSrc);
            }

            el.addEventListener("load", () => {
                setTimeout(() => el.classList.add("loaded"), 100);
            });
            el.addEventListener("error", () => console.log("error"));
        }

        function handleIntersect(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver() {
            if (window.IntersectionObserver) {
                const options = {
                    root: null,
                    rootMargin: "0px 0px 500px 0px",
                    threshold: "0",
                };
                const observer = new IntersectionObserver(handleIntersect, options);
                observer.observe(el);
            }
        }

        if (lazy && window.IntersectionObserver) {
            createObserver();
        } else {
            loadImage();
        }
    };

    Vue.directive("lazy", {
        mounted(el, binding) {
            lazyMethod(el, binding);
        },
        updated(el, binding) {
            lazyMethod(el, binding);
        },
    });
};

export default LazyLoadWebP;
