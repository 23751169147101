/* eslint-disable */
import nprogress from "nprogress";

const defaults = {
    latencyThreshold: 100,
    router: true,
    http: true,
};

export default {
    install: (app, options) => {
        // eslint-disable-next-line no-param-reassign
        options = { ...defaults, ...options };

        const np = nprogress.configure(options);

        let requestsTotal = 0;
        let requestsCompleted = 0;
        const { latencyThreshold, router: applyOnRouter, http: applyOnHttp } = options;
        let confirmed = true;

        function setComplete() {
            requestsTotal = 0;
            requestsCompleted = 0;
            np.done();
        }

        function initProgress() {
            if (requestsTotal === 0) {
                setTimeout(() => np.start(), latencyThreshold);
            }
            requestsTotal++;
            np.set(requestsCompleted / requestsTotal);
        }

        function increase() {
            // Finish progress bar 50 ms later
            setTimeout(() => {
                ++requestsCompleted;
                if (requestsCompleted >= requestsTotal) {
                    setComplete();
                } else {
                    np.set((requestsCompleted / requestsTotal) - 0.1);
                }
            }, latencyThreshold + 50);
        }

        const router = applyOnRouter && app.config.globalProperties.$router;
        if (router) {
            router.beforeEach((route, from, next) => {
                if (applyOnRouter && confirmed) {
                    initProgress();
                    confirmed = false;
                }
                next();
            });
            router.afterEach((route, from, next) => {
                if (applyOnRouter) {
                    increase();
                    confirmed = true;
                }
            });
        }

        app.config.globalProperties.$nprogress = np;
    },
};
