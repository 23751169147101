import { updateConfig } from "./config";
import init from "./init";
import event from "./tracking/track";

const install = function install(Vue, options = {}) {
    const { router } = options;

    delete options.router;
    updateConfig(options);

    init(router, options.onAnalyticsReady);

    const features = { event };

    if (!Vue.config.globalProperties.$analytics) {
        Vue.config.globalProperties.$analytics = {}
    }
    Vue.config.globalProperties.$analytics.fbq = features;
};

export default {
    install,
};
