const customerClassificationFromStorage = typeof (Storage) !== "undefined" && localStorage.cc ? localStorage.cc : "";

const behaviorState = {
    customerClassification: customerClassificationFromStorage,
};

const getters = {
    customerClassification: (state) => state.customerClassification,
};

const actions = {
    customerClassification({ commit }, classificationData) {
        setTimeout(() => {
            commit("customerClassification", classificationData);
            if (typeof (Storage) !== "undefined") {
                try {
                    localStorage.setItem("cc", classificationData);
                } catch (e) {
                    //
                }
            }
        }, 10);
    },
};

const mutations = {
    customerClassification(state, classificationData) {
        state.customerClassification = classificationData;
    },
};

export default {
    state: behaviorState,
    getters,
    actions,
    mutations,
};
