<template>
    <div class="nprogress-container" />
</template>

<script>
export default {
    name: "nprogress-container",
};
</script>

<style lang="css">
@import 'nprogress/nprogress.css';
</style>
