import {merge} from './utils'

/**
 * Default configuration
 */
let config = {
    autoTracking: true,
    id: null,
    ignoreRoutes: []
};

/**
 * Returns the new configuation object
 * @param  {Object} params
 * @return {Object}
 */
export const updateConfig = function (params) {
    return merge(config, params)
}

export default config