import config from './config'

/**
 * Whining helper
 * @param  {String} message
 */
export const warn = function (...message) {
    /* eslint-disable */
    console.warn(`[FacebookAnalytics] ${message.join(' ')}`)
    /* eslint-enable */
}

/**
 * Returns if a string exists in the array of routes
 * @param  {String} name
 * @return {Boolean}
 */
export const exists = function (name) {
    return !!(config.ignoreRoutes.length && config.ignoreRoutes.indexOf(name) !== -1)
}

/**
 * Merges two objects
 * @param  {Object} obj
 * @param  {Object} src
 * @return {Object}
 */
export const merge = function (obj, src) {
    Object.keys(src).forEach(function (key) {
        if (obj[key] && typeof obj[key] === 'object') {
            merge(obj[key], src[key])
            return
        }

        obj[key] = src[key]
    })

    return obj
}

export const getName = function (value) {
    return value.replace(/-/gi, '')
}

export const loadScript = function (url, domain) {
    return new Promise((resolve, reject) => {
        const n = window.fbq = function() {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!window._fbq)
            window._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
    
        const head = document.head || document.getElementsByTagName("head")[0];
        const body = document.body || document.getElementsByTagName("body")[0];
        const script = document.createElement("script");
    
        script.async = true;
        script.src = url;
        script.charset = "utf-8";
    
        if (domain) {
            const link = document.createElement("link");
    
            link.href = domain;
            link.rel = "preconnect";
    
            head.appendChild(link);
        }
        
        setTimeout(() => {
            body.appendChild(script);
        }, 500);
    
        script.onload = resolve;
        script.onerror = reject;
    });
  }