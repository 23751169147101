import shop from "../../shop";

const customerTypeFromLS = typeof (Storage) !== "undefined" && localStorage.ctype ? localStorage.ctype : "2";

const cartState = {
    cartBadge: 0,
    customerType: parseInt(customerTypeFromLS),
};

const getters = {
    cartBadge: (state) => state.cartBadge,
    customerType: (state) => state.customerType,
};

const actions = {
    cartBadge({ commit }) {
        shop.getCartBadge((response) => {
            commit("cartBadge", response.count);
            commit("customerType", response.ctype);
            localStorage.setItem("ctype", response.ctype);
        }, () => {
            commit("cartBadge", 0);
        });
    },
};

const mutations = {
    cartBadge(state, data) {
        state.cartBadge = data;
    },
    customerType(state, data) {
        state.customerType = data;
    },
};

export default {
    state: cartState,
    getters,
    actions,
    mutations,
};
